import {IManageUser} from '../../../types/user';
import {on, createReducer} from '@ngrx/store';
import {userLoadedSuccess} from './user.actions';
import * as Sentry from '@sentry/browser';

export const initialState: IManageUser = null;

export const userReducer = createReducer(
  initialState, on(
    userLoadedSuccess,
    (state, {user}) => {
      if(!!user) {
        Sentry.setUser(user);
      }
      return user;
    }
  )
);
